import React from 'react'
import {Polygon} from "@react-google-maps/api";
import {PolygonCoords} from "../util/MeshblockUtils";

const polygonOptions = {
    fillColor: '#329da8',
    fillOpacity: 0.25,
    strokeColor: '#329da8',
    strokeOpacity: 0.25,
    strokeWeight: 0,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
};

type HighlightedPolygonProps = {
    coordinates: PolygonCoords
}

export const HighlightedPolygon = (props: HighlightedPolygonProps) => {
    return (
        <Polygon path={props.coordinates} options={
            {
                ...polygonOptions,
                paths: props.coordinates
            }
        }/>
    );
}
