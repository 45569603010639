import React, {useEffect, useState} from 'react'
import {getNearbyOffensesBySuburbs, Offense,} from "../util/OffenseUtils";
import {getNearbyMeshblocks, meshblockRingsToPolygonCoords, PolygonInfo} from "../util/MeshblockUtils";
import {Affix, Col, message, notification, Row} from "antd";
import useWindowDimensions from "./Hooks";
import {getCurrentLocation, getNearbySuburbs} from "../util/Geolocation";
import {OffenseTypePieChart} from "./charts/OffenseTypePieChart";
import {OffenseDayRadarChart} from "./charts/OffenseDayRadarChart";
import {OffenseTimeLineChart} from "./charts/OffenseTimeLineChart";
import {Map} from "./Map";
import { useMediaQuery } from 'react-responsive'
import {useSwipeable} from "react-swipeable";
import {CarouselContainer, CarouselSlot, NEXT, Paper, PREV, Wrapper} from "./CarouselContainer";
import { Drawer, Button, Tooltip } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import {getDistance} from "geolib";

export type MeshblockData = {
    meshblock: string,
    polygon: PolygonInfo,
    offenses: Offense[]
}[];

message.config({
    top: 200
});

type Direction = typeof PREV | typeof NEXT;

interface CarouselState {
    pos: number;
    sliding: boolean;
    dir: Direction;
}

type CarouselAction =
    | { type: Direction, numItems: number }
    | { type: 'stopSliding' | 'reset' };


const initialState: CarouselState = { pos: 0, sliding: false, dir: NEXT };

function reducer(state: CarouselState, action: CarouselAction): CarouselState {
    switch (action.type) {
        case 'reset':
            return initialState;
        case PREV:
            return {
                ...state,
                dir: PREV,
                sliding: true,
                pos: state.pos === 0 ? action.numItems - 1 : state.pos - 1
            };
        case NEXT:
            return {
                ...state,
                dir: NEXT,
                sliding: true,
                pos: state.pos === action.numItems - 1 ? 0 : state.pos + 1
            };
        case 'stopSliding':
            return { ...state, sliding: false };
        default:
            return state;
    }
}

const getOrder = (index: number, pos: number, numItems: number) => {
    return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

export const CrimeMapContainer = () => {

    const [selectedAreas, setSelectedAreas] = useState([]);
    const [meshblockData, setMeshblockData]: [MeshblockData, any] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const {height, width} = useWindowDimensions();
    const [crimeDataLocation, setCrimeDataLocation] = useState();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 2100px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const [graphsVisible, setGraphsVisible] = useState(false);

    const slide = (dir: Direction) => {
        dispatch({ type: dir, numItems: 3 });
        setTimeout(() => {
            dispatch({ type: 'stopSliding' });
        }, 50);
    };
    const handlers = useSwipeable({
        onSwipedLeft: () => slide(NEXT),
        onSwipedRight: () => slide(PREV),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const gridStyles = (): {mapHeight: number, chartHeight: number, padding: string, mapPadding: string} => {
        if (isTabletOrMobile) {
            return({
                padding: "5px",
                mapPadding: "0px",
                mapHeight: height,
                chartHeight: 0.5 * height
            })
        }
        if (isBigScreen) {
            return({
                padding: "10px",
                mapPadding: "10px",
                mapHeight: 0.68 * height,
                chartHeight: 0.3 * height
            })
        }
        if (isDesktopOrLaptop) {
            return({
                padding: "10px",
                mapPadding: "10px",
                mapHeight: 0.58 * height,
                chartHeight: 0.4 * height
            })
        }
        return({
            padding: "10px",
            mapPadding: "10px",
            mapHeight: 0.58 * height,
            chartHeight: 0.4 * height
        })
    }

    useEffect(() => {
        async function initLocation() {
            try {
                const initalLocation = await getCurrentLocation();
                setCrimeDataLocation({
                    lat: initalLocation.latitude,
                    lng: initalLocation.longitude
                });
            } catch (err) {
                setCrimeDataLocation({
                    lat: -27.470125,
                    lng: 153.021072
                });
            }
        }
        initLocation();
    }, []);

    const loadCrimeData = async (mapInstance: google.maps.Map<Element>, initialLoad?: boolean) => {
        if (mapInstance) {
            const dist =
                getDistance({latitude: crimeDataLocation.lat, longitude: crimeDataLocation.lng}, {latitude: mapInstance.getCenter().lat(), longitude: mapInstance.getCenter().lng()});
            if (dist > 2000 || initialLoad) {
                const hide = message.loading('Loading crime data...', 0);
                setLoadingData(true);
                setSelectedAreas([]);
                const nearbySuburbs = await getNearbySuburbs(mapInstance.getCenter().lat(), mapInstance.getCenter().lng());
                const offensesForNearbySuburbs = await getNearbyOffensesBySuburbs(nearbySuburbs.map(suburb => suburb.name));
                const nearbyMeshblocks = await getNearbyMeshblocks(mapInstance.getCenter().lat(), mapInstance.getCenter().lng());
                const nearbyMeshblocksForOffenses = nearbyMeshblocks.results.filter((nearbyMeshblock) =>
                    offensesForNearbySuburbs.some(nearbyOffense => nearbyOffense["ABS Meshblock"] === nearbyMeshblock.value));
                const meshblockToOffenseData = nearbyMeshblocksForOffenses.map((nearbyMeshblock) => {
                    return ({
                        meshblock: nearbyMeshblock.value,
                        polygon: meshblockRingsToPolygonCoords(nearbyMeshblock.geometry, nearbyMeshblock.value),
                        offenses: offensesForNearbySuburbs.filter((offense) => offense["ABS Meshblock"] === nearbyMeshblock.value)
                    })
                });
                setCrimeDataLocation({lat: mapInstance.getCenter().lat(), lng: mapInstance.getCenter().lng()});
                setMeshblockData(meshblockToOffenseData);
                setLoadingData(false);
                hide();
            }
        }
    }

// TODO CM - remove default POIs from map
    return (
        <React.Fragment>
            <Affix style={{position: 'absolute', top: 0.001 * height, right: 0.001 * width, zIndex: 10000,}}>
                <p style={{background: "rgba(240, 240, 240, 0.85)", fontSize: "x-small"}}>Created by Connor Monaghan</p>
            </Affix>
            {isTabletOrMobileDevice && <Affix style={{ position: 'absolute', bottom: 0.03 * height, left: 0.03 * width, zIndex: 500 }}>
                <Button size={"large"} type="primary" shape="circle" icon={<BarChartOutlined />} onClick={() => setGraphsVisible(!graphsVisible)}/>
            </Affix>}
            <div style={{overflow: 'hidden', padding: gridStyles().mapPadding}}>
                <Row justify="space-around" align="middle">
                    <Col span={24}>
                        {crimeDataLocation &&
                        <div style={{
                            height: gridStyles().mapHeight,
                            boxShadow: "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12)"
                        }}>
                        <Map selectedAreas={selectedAreas}
                             meshblockData={meshblockData}
                             loadingData={loadingData}
                             initCenter={crimeDataLocation}
                                    // @ts-ignore
                             onSelectAreas={(selectedAreas) => setSelectedAreas(selectedAreas)}
                             onMapLoaded={async (mapInstance) => {
                                        await loadCrimeData(mapInstance, true);
                                        const args = {
                                            message: 'Queensland Crime Map',
                                            description: 'Click on a marker to see crime information for the area.',
                                            duration: 0,
                                        };
                                        // notification.open(args);
                                    }}
                             onMapDragged={async (mapInstance) => {
                                        await loadCrimeData(mapInstance);
                                    }}
                             onPlaceSelected={async (mapInstance) => {
                                        await loadCrimeData(mapInstance);
                                    }}
                        />
                        </div>}
                    </Col>
                </Row>
            </div>
            {isTabletOrMobileDevice ?
                <Drawer
                    placement="bottom"
                    onClose={() => {setGraphsVisible(false)}}
                    visible={graphsVisible}
                    mask={false}
                    height={gridStyles().chartHeight + 20}
                    drawerStyle={{ padding: "0px", margin: "0px" }}
                    bodyStyle={{overflow: "hidden", padding: "6px"}}
                >
                {/*<Paper>*/}
                    <div {...handlers}>
                        <Wrapper>
                            <CarouselContainer sliding={state.sliding} dir={state.dir} style={{height: gridStyles().chartHeight}}>
                                <CarouselSlot order={getOrder(0, state.pos, 3)}>
                                    <OffenseTypePieChart selectedAreas={selectedAreas} meshblockData={meshblockData}/>
                                </CarouselSlot>
                                <CarouselSlot order={getOrder(1, state.pos, 3)}>
                                    <OffenseDayRadarChart selectedAreas={selectedAreas} meshblockData={meshblockData}/>
                                </CarouselSlot>
                                <CarouselSlot order={getOrder(2, state.pos, 3)}>
                                    <OffenseTimeLineChart selectedAreas={selectedAreas} meshblockData={meshblockData}/>
                                </CarouselSlot>
                            </CarouselContainer>
                        </Wrapper>
                    </div>
                {/*</Paper>*/}
                </Drawer> :
                <div style={{ overflow: 'hidden', paddingLeft: gridStyles().padding, paddingRight: gridStyles().padding}}>
                    <Row gutter={[8, 8]}>
                        <Col span={7}>
                            <div style={{
                                height: gridStyles().chartHeight,
                                background: "#eee",
                                boxShadow: "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12)"
                            }}>
                                <OffenseTypePieChart selectedAreas={selectedAreas} meshblockData={meshblockData}/>
                            </div>
                        </Col>
                        <Col span={7}>
                            <div style={{
                                height: gridStyles().chartHeight,
                                background: "#eee",
                                boxShadow: "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12)"
                            }}>
                                <OffenseDayRadarChart selectedAreas={selectedAreas} meshblockData={meshblockData}/>
                            </div>
                        </Col>
                        <Col span={10}>
                            <div style={{
                                height: gridStyles().chartHeight,
                                background: "#eee",
                                boxShadow: "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12)"
                            }}>
                                <OffenseTimeLineChart selectedAreas={selectedAreas} meshblockData={meshblockData}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            }

        </React.Fragment>
    );
}