import {SuburbInfo, SUBURBS} from "../geodata/Suburbs";
import {getDistance} from "geolib";

const NEARBY_RADIUS = 3;

export const getCurrentLocation = async (): Promise<Coordinates> => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (success) => {
                resolve(success.coords);
            },
            (error) => {
                reject(error);
            });
    });
}

export const getNearbySuburbs = async (lat: number, lng: number): Promise<SuburbInfo[]> => {
    let nearbySuburbs: SuburbInfo[] = [];
    SUBURBS.forEach((suburb) => {
        const dist =
            getDistance({latitude: lat, longitude: lng}, {latitude: suburb.centre.lat, longitude: suburb.centre.lng});
        suburb.name = suburb.name.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        if (dist < NEARBY_RADIUS * 1000) {
            nearbySuburbs.push(suburb);
        }
    });
    return nearbySuburbs;
};