import styled from 'styled-components';

export const NEXT = 'NEXT';
export const PREV = 'PREV';

export const Paper = styled.div``;

export const CarouselContainer = styled.div<{ sliding: boolean }>`
  display: flex;
  transition: ${(props: any) => (props.sliding ? "none" : "transform 1s ease")};
  transform: ${(props: any) => {
    if (!props.sliding) return "translateX(calc(-100%))";
    if (props.dir === PREV) return "translateX(calc(2 * (-100%)))";
    return "translateX(0%)";
}};
`;

export const CarouselSlot = styled.div<{ order: number }>`
  flex: 1 0 100%;
  flex-basis: 100%;
  margin-right: 0px;
  order: ${(props: any) => props.order};
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;
