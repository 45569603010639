import axios from 'axios';
import {getCenter} from "geolib";

const IDENTIFY_URL = "https://geo.abs.gov.au/arcgis/rest/services/ASGS2016/MB/MapServer/identify";

export type PolygonCoords = {
    lng: number,
    lat: number
}[]

export type PolygonInfo = {
    coords: PolygonCoords,
    centre: {
        lat: number,
        lng: number
    }
}

// TODO CM - other fields here, only calling out what we use
type MeshblockGeometry = {
    rings: number[][][]
}

type MeshblockGeometryResult = {
    results: {
        value: string,
        attributes: { [key: string]: string },
        geometry: MeshblockGeometry
    }[]
}

const polygonCoordsCache: { [meshblockCode: string]: PolygonInfo } = {};

export const getNearbyMeshblocks = async (lat: number, lng: number): Promise<MeshblockGeometryResult> => {
    const bodyFormData = new FormData();
    bodyFormData.append("geometry", JSON.stringify({x: lng, y: lat}));
    bodyFormData.append("geometryType", "esriGeometryPoint");
    bodyFormData.append("sr", "4326");
    bodyFormData.append("layers", "MB");
    bodyFormData.append("tolerance", "10");
    bodyFormData.append("mapExtent", `${lng - 0.2},${lat - 0.2},${lng + 0.2},${lat + 0.2}`);
    bodyFormData.append("imageDisplay", "300,225,96");
    bodyFormData.append("returnGeometry", "true");
    bodyFormData.append("f", "pjson");
    const result = await axios({
        method: "post",
        url: IDENTIFY_URL,
        data: bodyFormData,
        headers: {"Content-Type": "multipart/form-data"},
    });
    return result.data;
};

export const meshblockRingsToPolygonCoords = (geometry: MeshblockGeometry, meshblockCode: string): PolygonInfo => {
    if (polygonCoordsCache[meshblockCode]) {
        return polygonCoordsCache[meshblockCode];
    }
    const {rings} = geometry;
    const ringsFlat = rings.flat();
    const polygonCoords = ringsFlat.map((ring) => {
        return ({
            lat: ring[1],
            lng: ring[0]
        })
    });
    const latLngCoordsDist = polygonCoords.map((coords) => {
        return ({latitude: coords.lat, longitude: coords.lng})
    });
    const latLngCenter = getCenter(latLngCoordsDist) as any;
    const meshblockPolygonInfo = {
        coords: polygonCoords,
        centre: {
            lat: latLngCenter.latitude,
            lng: latLngCenter.longitude
        }
    }
    polygonCoordsCache[meshblockCode] = meshblockPolygonInfo;
    return meshblockPolygonInfo;
};