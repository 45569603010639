import {Label, Legend, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import React from "react";
import useWindowDimensions from "../Hooks";
import Title from "antd/es/typography/Title";
import {MeshblockData} from "../CrimeMapContainer";
import {useMediaQuery} from "react-responsive";

type OffensePieChartProps = {
    selectedAreas: string[],
    meshblockData: MeshblockData
}

const colours = [
    "#003f5c",
    "#ffa600",
    "#d45087",
    "#665191",
    "#f95d6a",
    "#2f4b7c",
    "#ff7c43",
    "#a05195"
];

const placeHolderData = [
    {
        Type: "A",
        Count: 60,
        fill: "#aaaaaa"
    },
    {
        Type: "B",
        Count: 20,
        fill: "#aaaaaa"
    },
    {
        Type: "C",
        Count: 35,
        fill: "#aaaaaa"
    },
];

const offenseTypesToPieData = (selectedAreas: string[], mbData: MeshblockData): { Type: string, Count: number }[] => {
    const offenseTypes = mbData.filter((mb) => selectedAreas.includes(mb.meshblock))
        .map((mb) => mb.offenses)
        .flat()
        .map((offense) => offense.Type);
    const counts: { [type: string]: number } = {};
    for (let i = 0; i < offenseTypes.length; i++) {
        const num = offenseTypes[i];
        counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    const data = Object.keys(counts).map((type) => {
        return ({
            Type: type,
            Count: counts[type]
        })
    });
    data.sort((a, b) => b.Count - a.Count);
    let splicedData = data;
    if (data.length > 8) {
        const otherOffenses = data.splice(7);
        const otherOffensesCount = otherOffenses.map((offense) => offense.Count)
            .reduce((a, b) => a + b, 0);
        splicedData = [
            ...data.splice(0, 6),
            {Type: "Other", Count: otherOffensesCount}
        ]
    }
    return splicedData.map((data, index) => {
        return ({
            ...data,
            fill: colours[index]
        });
    });
}

export const OffenseTypePieChart = (props: OffensePieChartProps) => {

    const {height} = useWindowDimensions();
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    return(
        <React.Fragment>
            <div>
                <p>Offense Type</p>
            </div>
            <ResponsiveContainer width={"100%"} height={"90%"}>
                <PieChart margin={{top: 0, left: 0, right: 0, bottom: 0}}>
                    {props.selectedAreas.length &&
                    <Pie data={offenseTypesToPieData(props.selectedAreas, props.meshblockData)} dataKey="Count"
                         nameKey="Type" cx="50%" cy="50%" outerRadius={isTabletOrMobileDevice ? 80 : 110}
                         innerRadius={20} fill="#eee" paddingAngle={1} minAngle={1}/>}
                    {!props.selectedAreas.length &&
                    <Pie data={placeHolderData} dataKey="Count" nameKey="Type" cx="50%" cy="50%"
                         outerRadius={isTabletOrMobileDevice ? 90 : 110}
                         innerRadius={20} fill="#eee" paddingAngle={1} minAngle={1}/>
                    }
                    <Legend/>
                    <Tooltip/>
                </PieChart>
            </ResponsiveContainer>
        </React.Fragment>
    )
}