import {MeshblockData} from "../CrimeMapContainer";
import {PolarAngleAxis, PolarGrid, Radar, RadarChart, ResponsiveContainer, Tooltip} from "recharts";
import React from "react";
import useWindowDimensions from "../Hooks";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import {useMediaQuery} from "react-responsive";

type OffenseDayRadarChartProps = {
    selectedAreas: string[],
    meshblockData: MeshblockData
}

export const OffenseDayRadarChart = (props: OffenseDayRadarChartProps) => {

    const {height} = useWindowDimensions();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 2100px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    const offensesToRadarData = (selectedAreas: string[], mbData: MeshblockData): { Day: string, Count: number }[] => {
        const offenseDays = mbData.filter((mb) => selectedAreas.includes(mb.meshblock))
            .map((mb) => mb.offenses)
            .flat()
            .map((offense) => {
                if (isTabletOrMobileDevice) {
                    return dayjs(offense.Date).format('ddd')
                }
                return dayjs(offense.Date).format('dddd')
            });
        const counts: { [type: string]: number } = {};
        for (let i = 0; i < offenseDays.length; i++) {
            const num = offenseDays[i];
            counts[num] = counts[num] ? counts[num] + 1 : 1;
        }
        if (isTabletOrMobileDevice) {
            return ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"]
                .map((day) => {
                    return({
                        Day: day,
                        Count: offenseDays.filter((d) => d === day).length
                    })
                });
        }
        return ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
            .map((day) => {
                return({
                    Day: day,
                    Count: offenseDays.filter((d) => d === day).length
                })
            });
    }


    return(
        <React.Fragment>
            <div>
                <p>Day of Offense</p>
            </div>
            <ResponsiveContainer width={"100%"} height={"80%"}>
                <RadarChart outerRadius={isTabletOrMobileDevice ? 115: 130}
                            data={offensesToRadarData(props.selectedAreas, props.meshblockData)}>
                    <PolarGrid/>
                    <PolarAngleAxis dataKey="Day"/>
                    <Radar name="Count" dataKey="Count" stroke="#8884d8" fill="#8884d8"
                           fillOpacity={0.6}/>
                    <Tooltip/>
                </RadarChart>
            </ResponsiveContainer>
        </React.Fragment>
    )
}