import axios from 'axios';

export type Offense = {
    Type: string,
    Date: string,
    Postcode: number,
    'Area of Interest': string,
    'ABS Meshblock': string
}

type OffencesResult = Offense[];

const OFFENSE_URL = "https://a5c7zwf7e5.execute-api.ap-southeast-2.amazonaws.com/dev/offences";

const offensesBySuburbCache: {[suburb: string]: Offense[]} = {};

export const getNearbyOffensesBySuburbs = async (suburbs: string[]): Promise<Offense[]> => {
    const offencesBySuburbPromise = suburbs.map(async (suburb) => {
        if (!offensesBySuburbCache[suburb]) {
            const url = `${OFFENSE_URL}?locationType=SUBURB&startDate=06-26-2018&locationName=${suburb}&endDate=08-26-2019&format=JSON`;
            const offensesBySuburbResult = await axios.get<OffencesResult>(url);
            offensesBySuburbCache[suburb] = offensesBySuburbResult.data;
        } else {
            return offensesBySuburbCache[suburb];
        }
    });
    await Promise.all(offencesBySuburbPromise);
    const nearbyOffenses: Offense[] = [];
    suburbs.forEach((suburb) => {
        nearbyOffenses.push(...offensesBySuburbCache[suburb]);
    });
    return nearbyOffenses;
}

