import React from 'react';
import './App.css';
import {CrimeMapContainer} from "./components/CrimeMapContainer";
import {Layout} from "antd";

const {Content} = Layout;

const App = () => {

    return (
        <div className="App">
            <Layout className="site-layout">
                <Content style={{overflow: 'initial', height: '100vh'}}>
                    <CrimeMapContainer />
                </Content>
            </Layout>
        </div>
    );
}

export default App;
