import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React from "react";
import useWindowDimensions from "../Hooks";
import Title from "antd/es/typography/Title";
import {MeshblockData} from "../CrimeMapContainer";
import dayjs from "dayjs";

type OffenseTimeLineChartProps = {
    selectedAreas: string[],
    meshblockData: MeshblockData
}

const offensesToHourData = (selectedAreas: string[], mbData: MeshblockData): { 'Hour': string, 'Count': number }[] => {
    const offenseHours = mbData.filter((mb) => selectedAreas.includes(mb.meshblock))
        .map((mb) => mb.offenses)
        .flat()
        .map((offense) => dayjs(offense.Date).format('h A'));
    const counts: { [type: string]: number } = {};
    for (let i = 0; i < offenseHours.length; i++) {
        const num = offenseHours[i];
        counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    return ["12 AM", "2 AM", "4 AM", "6 AM", "8 AM", "10 AM", "12 PM", "2 PM", "4 PM", "6 PM", "8 PM", "10 PM"]
        .map((time) => {
            return ({
                Hour: time,
                Count: offenseHours.filter((d) => d === time).length
            })
        });
}

export const OffenseTimeLineChart = (props: OffenseTimeLineChartProps) => {

    const {height} = useWindowDimensions();

    return (
        <React.Fragment>
            <div>
                <p>Time of Offense</p>
            </div>
            <ResponsiveContainer width={"95%"} height={"80%"}>
                <LineChart
                    data={offensesToHourData(props.selectedAreas, props.meshblockData)}
                    margin={{top: 0.04 * height, right: 0, left: 0, bottom: 0,}}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="Hour" interval={"preserveStart"}/>
                    <YAxis/>
                    <Tooltip/>
                    <Line type="monotone" dataKey="Count" stroke="#82ca9d"/>
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
    )
}