import {GoogleMap, Marker, MarkerClusterer, useLoadScript} from "@react-google-maps/api";
import {AutocompleteInput} from "./AutocompleteInput";
import {HighlightedPolygon} from "./HighlightedPolygon";
import React, {useState} from "react";
import useWindowDimensions from "./Hooks";
import {MeshblockData} from "./CrimeMapContainer";

const mapContainerStyle = {
    height: "100%",
    width: "100%"
};

const mapStyles = [
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
];

type MapProps = {
    selectedAreas: string[],
    meshblockData: MeshblockData,
    initCenter: {
        lat: number,
        lng: number
    },
    loadingData: boolean,
    onSelectAreas: (selectedAreas: string[]) => void,
    onMapLoaded: (mapInstance: google.maps.Map<Element>) => Promise<void>
    onMapDragged: (mapInstance: google.maps.Map<Element>) => Promise<void>
    onPlaceSelected: (mapInstance: google.maps.Map<Element>) => Promise<void>
}

function debounce(func: any, timeout = 1000){
    let timer: any;
    return (...args: any[]) => {
        clearTimeout(timer);
        // @ts-ignore
        timer = setTimeout(() => {func.apply(this, args); }, timeout);
    };
}
const LIBRARIES = ["places", "visualization"];

export const Map = (props: MapProps) => {

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: "AIzaSyDCrItiNHHckwtBfFKg6ByllBKIpcYhqeU",
        libraries: LIBRARIES
    });
    const {height} = useWindowDimensions();
    const [centre, setCentre] = useState(props.initCenter);
    const [mapInstance, setMapInstance] = useState();

    // @ts-ignore
    const onClickMarkerCluster = (cluster) => {
        // @ts-ignore
        const selectedMeshblocks = cluster.getMarkers().map((marker) => {
            return {
                lat: marker.position.lat(),
                lng: marker.position.lng()
            }
        })
            .map((coords: { lat: number, lng: number }) => {
                return props.meshblockData.filter((meshblock) => meshblock.polygon.centre.lat === coords.lat && meshblock.polygon.centre.lng === coords.lng)[0].meshblock;
            });
        props.onSelectAreas(selectedMeshblocks);
    }

    const clusterTextCalculator = (markers: any[]) => {
        const meshblockList = markers.map((marker) => {
            return {
                lat: marker.position.lat(),
                lng: marker.position.lng()
            }
        })
            .map((coords: { lat: number, lng: number }) => {
                const mb = props.meshblockData.filter((meshblock) => meshblock.polygon.centre.lat === coords.lat && meshblock.polygon.centre.lng === coords.lng)[0];
                return mb;
            });
        const offenses = meshblockList.map((mbData) => mbData.offenses).flat();
        let index;
        if (offenses.length < 10) {
            index = 1;
        } else if (offenses.length < 50) {
            index = 2;
        } else if (offenses.length < 100) {
            index = 3;
        } else if (offenses.length < 1000) {
            index = 4;
        } else {
            index = 5;
        }
        return {
            text: offenses.length,
            index
        };
    }

    if (!isLoaded) {
        return <p>loading...</p>;
    } else if (loadError) {
        return <p>Error displaying the map</p>;
    }

    return(
        <React.Fragment>
            <GoogleMap
                id='example-map'
                mapContainerStyle={mapContainerStyle}
                // @ts-ignore
                options={{
                    styles: mapStyles,
                    maxZoom: 19,
                    minZoom: 12,
                    zoomControl: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP
                    },
                    fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeControl: false,
                    draggable: !props.loadingData,
                    gestureHandling: "greedy"
                }}
                onLoad={async (mapInstance) => {
                    if (mapInstance) {
                        setMapInstance(mapInstance);
                        await props.onMapLoaded(mapInstance);
                    }
                }}
                onZoomChanged={() => props.onSelectAreas([])}
                zoom={17}
                center={centre}
                onDragEnd={debounce(async () => {
                    if (mapInstance) {
                        await props.onMapDragged(mapInstance)
                    }
                })}
            >
                <AutocompleteInput onSelectPlace={async (lat, lng) => {
                    setCentre({lat, lng});
                    await props.onPlaceSelected(mapInstance);
                }}/>
                {!props.loadingData && <MarkerClusterer
                    // @ts-ignore
                    calculator={clusterTextCalculator}
                    options={{}}
                    onClick={onClickMarkerCluster} averageCenter={true} zoomOnClick={false}
                    minimumClusterSize={1} gridSize={120}>
                    {(clusterer) =>
                        props.meshblockData.map((mbData) => (
                            <Marker key={mbData.meshblock} position={mbData.polygon.centre}
                                    clusterer={clusterer}/>
                        ))
                    }
                </MarkerClusterer>}
                {props.selectedAreas && props.selectedAreas.map((meshblock: string) =>
                    <HighlightedPolygon
                        key={meshblock} coordinates={props.meshblockData.filter((mbData) => mbData.meshblock === meshblock)[0].polygon.coords}
                    />
                )}
            </GoogleMap>
        </React.Fragment>
    );

}