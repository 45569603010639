import React, {Component} from 'react'
import {Autocomplete} from "@react-google-maps/api";

type AutocompleteInputProps = {
    onSelectPlace: (lat: number, lng: number) => void
}

export class AutocompleteInput extends Component<AutocompleteInputProps> {

    private autocomplete: any;

    constructor(props: any) {
        super(props)
        this.autocomplete = null
        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)
    }

    onLoad(autocomplete: any) {
        this.autocomplete = autocomplete
    }

    onPlaceChanged(onSelectPlace: (lat: number, lng: number) => void) {
        if (this.autocomplete !== null) {
            const lat = this.autocomplete.getPlace().geometry.location.lat();
            const lng = this.autocomplete.getPlace().geometry.location.lng();
            onSelectPlace(lat, lng);
        } else {
            console.log('AutocompleteInput is not loaded yet!')
        }
    }

    render() {
        return (
            <Autocomplete
                onLoad={this.onLoad}
                onPlaceChanged={() => this.onPlaceChanged(this.props.onSelectPlace)}
                restrictions={{country: ['au']}}
                bounds={{
                    north: -9.795678,
                    south: -29.840644,
                    east: 154.447478,
                    west: 137.209413
                }}
                options={{strictBounds: true}}
                fields={["geometry.location"]}
            >
                <input
                    type="text"
                    placeholder="Enter a location"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        left: "50%",
                        marginLeft: "-120px"
                    }}
                />
            </Autocomplete>
        )
    }
}